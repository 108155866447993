import React from "react";
import {
    Card,
    Col,
    Container,
    Row
} from "reactstrap";
import Header from "./sections/Header";
import YZHNavbar from "../components/YZHNavbar";
import DarkFooter from "../components/DarkFooter";
import {InfoWindow, Map, Marker, NavigationControl, ZoomControl} from "react-bmapgl/dist";
import SocialPlatform from "./sections/SocialPlatform";

function Info() {

    React.useEffect(() => {
        document.body.classList.add("yzh-info-page");

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("yzh-info-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <YZHNavbar />
            <div className="wrapper">
                <div className="main">
                    <div className="section section-about-us">
                        <>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview">
                            </div>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="8">
                                    <h5>景区信息</h5>
                                </Col>
                            </Row>
                            <Map center={{lng: 115.903169, lat: 31.250697}} zoom="13">
                                <Marker position={{lng: 115.903169, lat: 31.250697}} />
                                <NavigationControl />
                                <ZoomControl />
                                <InfoWindow position={{lng: 115.903169, lat: 31.250697}}
                                            text="国家AAAA级旅游景区、大别山国家地质公园、国家水利风景区，华东最后一片原始森林。"
                                            title="燕子河大峡谷"/>
                            </Map>
                        </>
                        <Container style={{marginTop: 60}}>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <img
                                        alt="..."
                                        style={{marginBottom: 20, width: 150}}
                                        src={require("assets/img/logo.png").default}
                                    ></img>
                                    <h5 className="text-left">景区开放时间</h5>
                                    <h6 className="text-left">周一至周日</h6>
                                    <h6 className="text-left">07:30 - 18:30</h6>
                                    <p className="text-left">请关注我们的社交媒体获取
                                        最新景区开闭园时间、活动、及动态</p>
                                    <p className="text-left">
                                        <SocialPlatform />
                                    </p>
                                    <p className="text-left">景区服务热线：0564-7527058</p>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="4">
                                    <img
                                        alt="..."
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/view-i-1.png").default}
                                    ></img>
                                    <h6 className="text-left">安徽省六安市金寨县燕子河镇大峡谷村</h6>
                                    <a href="#"
                                       onClick={(e) => {
                                        e.preventDefault();
                                        window.open("https://map.baidu.com/poi/%E7%87%95%E5%AD%90%E6%B2%B3%E5%A4%A7%E5%B3%A1%E8%B0%B7/@12902421.252379041,3643174.223518465,16.3z?uid=1cdc4a4b62d0661726e0a00e&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl")
                                    }}><h6 className="text-left">导航去燕子河大峡谷 →</h6></a>
                                    <a href="#"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           window.open("https://www.findmaps.cn/maps/mapsV2/index.html#/?tenant_id=4")
                                       }}
                                    ><h6 className="text-left">查看景区电子地图 →</h6></a>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="4">
                                    <h6 className="text-left">成人&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;￥55</h6>
                                    <h6 className="text-left">老年人&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;￥27</h6>
                                    <h6 className="text-left text-muted">60-65岁</h6>
                                    <h6 className="text-left">儿童&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;￥27</h6>
                                    <h6 className="text-left text-muted">6-18岁</h6>
                                    <h6 className="text-left">学生&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;￥27</h6>
                                    <h6 className="text-left text-muted">持有效证件</h6>
                                    <h6 className="text-left">免票政策</h6>
                                    <h6 className="text-left text-muted">军人、现役警官、现役消防员、
                                        残疾人、记者、导游、
                                        6周岁以下儿童、
                                        65周岁以上老年人、
                                        金寨户籍。
                                        须持本人有效证件兑换门票。</h6>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default Info;
