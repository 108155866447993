import React from "react";

import {isMobile} from "react-device-detect";
import {Popover} from "react-tiny-popover";

function SocialPlatform(props) {
    const [isPopoverOpenW, setIsPopoverOpenW] = React.useState(false);
    const [isPopoverOpenT, setIsPopoverOpenT] = React.useState(false);
    const [isPopoverOpenR, setIsPopoverOpenR] = React.useState(false);

    React.useEffect(() => {
        document.body.classList.add("yzh-about-page");

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("yzh-landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            {isMobile ?
                <a href="#"
                   onClick={(e) => {
                       e.preventDefault();
                       window.open("https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA4NDQ2MDIwMQ==#wechat_redirect")
                   }}
                >
                    <img
                        alt="..."
                        src={props.color === "white" ? require("assets/img/wechat-white.svg").default : require("assets/img/wechat.svg").default}
                        style={{marginRight: 15}}
                    ></img>
                </a> :
                <>
                    <Popover
                        isOpen={isPopoverOpenW}
                        onClickOutside={() => setIsPopoverOpenW(false)}
                        positions={props.position ? [props.position] : ['top', 'bottom', 'left', 'right']} // preferred positions by priority
                        content={<div>                    <img
                            alt="..."
                            src={require("assets/img/wechat.jpg").default}
                            style={{marginRight: 15, width: 100}}
                        ></img> </div>}
                    >
                        <a href="#"
                           onClick={(e) => {                e.preventDefault();
                            setIsPopoverOpenW(!isPopoverOpenW)}}>
                            <img
                                alt="..."
                                src={props.color === "white" ? require("assets/img/wechat-white.svg").default : require("assets/img/wechat.svg").default}
                                style={{marginRight: 15}}
                            ></img>
                        </a>
                    </Popover>
                </>
            }
            {props.align === "vertical" && <div/>}
            {isMobile ?
                <a href="#"
                   onClick={(e) => {
                       e.preventDefault();
                       window.open("https://v.douyin.com/6FQtf9B/")
                   }}
                >
                    <img
                        alt="..."
                        src={props.color === "white" ? require("assets/img/tiktok-white.svg").default : require("assets/img/tiktok.svg").default}
                        style={{marginRight: 15}}
                    ></img>
                </a> :
                <>
                    <Popover
                        isOpen={isPopoverOpenT}
                        onClickOutside={() => setIsPopoverOpenT(false)}
                        positions={props.position ? [props.position] : ['top', 'bottom', 'left', 'right']} // preferred positions by priority
                        content={<div>                    <img
                            alt="..."
                            src={require("assets/img/douyin.png").default}
                            style={{marginRight: 15, width: 100}}
                        ></img> </div>}
                    >
                        <a href="#"
                           onClick={(e) => {                e.preventDefault();
                            setIsPopoverOpenT(!isPopoverOpenT)}}>
                            <img
                                alt="..."
                                src={props.color === "white" ? require("assets/img/tiktok-white.svg").default : require("assets/img/tiktok.svg").default}
                                style={{marginRight: 15}}
                            ></img>
                        </a>
                    </Popover>
                </>
            }
            {props.align === "vertical" && <div/>}

            {isMobile ?
                <a href="#"
                   onClick={(e) => {
                       e.preventDefault();
                       window.open("https://www.xiaohongshu.com/user/profile/56aef063b8c8b463bda13ac8?xhsshare=CopyLink&appuid=5c19e1d3000000000703022d&apptime=1662550883")
                   }}
                >
                    <img
                        alt="..."
                        src={require("assets/img/redbook.svg").default}
                        style={{marginRight: 15}}
                    ></img>
                </a> :
                <>
                    <Popover
                        isOpen={isPopoverOpenR}
                        onClickOutside={() => setIsPopoverOpenR(false)}
                        positions={ props.position ? [props.position] : ['top', 'bottom', 'left', 'right']} // preferred positions by priority
                        content={<div>
                            <img
                            alt="..."
                            src={require("assets/img/redbook.png").default}
                            style={{marginRight: 15, width: 100}}
                        ></img> </div>}
                    >
                        <a href="#" onClick={(e) => {                e.preventDefault();
                             setIsPopoverOpenR(!isPopoverOpenR)}}>
                            <img
                                alt="..."
                                src={props.color === "white" ? require("assets/img/redbook-white.svg").default : require("assets/img/redbook.svg").default}
                                style={{marginRight: 15}}
                            ></img>
                        </a>
                    </Popover>
                </>
            }
        </>
    );
}

export default SocialPlatform;
