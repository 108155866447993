import React from "react";
import {
    Card,
    Col,
    Container,
    Row
} from "reactstrap";
import Header from "./sections/Header";
import Carousel from "./sections/Carousel";
import YZHNavbar from "../components/YZHNavbar";
import Tabs from "./sections/Tabs";
import DarkFooter from "../components/DarkFooter";
import {Link} from "react-router-dom";

function Landing() {
    React.useEffect(() => {
        document.body.classList.add("yzh-landing-page");

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("yzh-landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <YZHNavbar />
            <div className="wrapper">
                <Header/>
                <div className="main">
                    <div className="section section-about-us">
                        <Container>
                            <Row  id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="8">
                                    <h5>多种游玩体验</h5>
                                    <h3 className="title">适合所有人的活动</h3>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <div className="card card-background" style={{backgroundImage: `url(${require("assets/img/act1.png").default}`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                        <div className="text-left" style={{marginLeft: 15, marginTop: 280}}>
                                            <h5 style={{color: '#ffffff', fontWeight: "bold"}}>徒步</h5>
                                            <h6 style={{color: '#c4dab1'}} >4800米无障碍栈道</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <div className="card card-background" style={{backgroundImage: `url(${require("assets/img/act2.png").default}`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                        <div className="text-left" style={{marginLeft: 15, marginTop: 280}}>
                                            <h5 style={{color: '#ffffff', fontWeight: "bold"}}>露营</h5>
                                            <h6 style={{color: '#c4dab1'}} >4000平超大草坪</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <div className="card card-background" style={{backgroundImage: `url(${require("assets/img/act3.png").default}`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                        <div className="text-left" style={{marginLeft: 15, marginTop: 280}}>
                                            <h5 style={{color: '#ffffff', fontWeight: "bold"}}>度假康养</h5>
                                            <h6 style={{color: '#c4dab1'}} >高尔夫球场，网球场，室内游泳池</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <div className="card card-background" style={{backgroundImage: `url(${require("assets/img/act4.png").default}`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                        <div className="text-left" style={{marginLeft: 15, marginTop: 280}}>
                                            <h5 style={{color: '#ffffff', fontWeight: "bold"}}>游玩项目</h5>
                                            <h6 style={{color: '#c4dab1'}} >卡丁车，山体滑道，玻璃桥</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <Link to={"/游玩体验"}><button type="button" className="btn btn-default">了解详情</button></Link>
                                </Col>
                            </Row>
                            <div className="section-story-overview">
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" md={6}>
                                            <h5>关于我们</h5>
                                        <h3 className="title">中国·安徽·燕子河大峡谷</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={7}>
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" + require("assets/img/pg11.jpg").default + ")",
                                            }}
                                        >
                                        </div>
                                        <div
                                            className="image-container"
                                            style={{
                                                backgroundImage:
                                                    "url(" + require("assets/img/pg11.png").default + ")",
                                            }}
                                        >
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <h4>
                                            国家AAAA级旅游景区
                                        </h4>
                                        <p>燕子河大峡谷是国家AAAA级旅游景区、大别山（六安）国家地质公园核心园区、国家水利风景区，位于安徽省六安市金寨县境内，与华东最后一片原始森林天堂寨毗邻，全长约6000米。燕子河大峡谷天作地成，气象万千。</p>
                                        <h4>
                                            中国·安徽红岭公路
                                        </h4>
                                        <p>中国红岭公路风景道由500里仙风道古自驾线、红色征程主题线和马丁公路越野线三部分组成，全程800里，串联了金寨红军广场、汤家汇红色小镇、大湾村、刘邓大军千里跃进大别山前方指挥部等著名红色文化景点和天堂寨、燕子河大峡谷、梅山水库、马鬃岭、茶山花海、龙津溪地等著名自然生态景区。</p>
                                        <h4>
                                            全程无障碍栈道
                                        </h4>
                                        <p>
                                            燕子河大峡谷景区以AAAAA级标准建设的无障碍步道，起点为景区东门，终点为核心景点——天坑仙境，顺峡谷峭壁走势悬空而建。栈道全长约2800米，为2米宽无障碍新型步道，可推婴儿车或残疾人轮椅直至天坑仙境游览。
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            <div className="separator separator-primary"></div>
                            <div  style={{paddingBottom: 300}}>
                            </div>
                            <div  style={{paddingBottom: 200, backgroundColor: "white"}}>
                            </div>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview" style={{marginTop: -500}}>
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" md={6}>
                                        <h5>酒店及度假村</h5>
                                        <h3 className="title">居停下榻</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" md={5}>
                                      <img
                                          alt="..."
                                          className=" img-fluid img-raised"
                                          style={{marginBottom: 20}}
                                          src={require("assets/img/yuyuan1.png").default}
                                      ></img>
                                        <h5 className={"text-left"}>仙境度假村</h5>
                                        <p className="text-muted text-left">￥498起</p>
                                    </Col>
                                    <Col className="ml-auto mr-auto mb-auto text-center" md={5}>
                                        <img
                                            alt="..."
                                            className=" img-fluid img-raised"
                                            style={{marginBottom: 20}}
                                            src={require("assets/img/hotel.png").default}
                                        ></img>
                                        <h5 className={"text-left"}>仙境酒店</h5>
                                        <p className="text-muted text-left">￥188起</p>
                                    </Col>
                                </Row>
                            </div>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview">
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" md={6}>
                                        <h5>荣誉及好评</h5>
                                        <h3 className="title">我们引以为豪</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="ml-auto mr-auto text-center" md={3}>
                                        <img
                                            alt="..."
                                            style={{marginBottom: 20}}
                                            src={require("assets/img/geo.png").default}
                                        ></img>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center" md={3}>
                                        <img
                                            alt="..."
                                            style={{marginBottom: 20}}
                                            src={require("assets/img/4a.png").default}
                                        ></img>
                                    </Col>
                                    <Col className="ml-auto mr-auto text-center" md={3}>
                                        <img
                                            alt="..."
                                            style={{marginBottom: 20}}
                                            src={require("assets/img/water.png").default}
                                        ></img>
                                    </Col>
                                </Row>
                                    <div
                                        className="image-container"
                                        style={{
                                            backgroundImage:
                                                "url(" + require("assets/img/view1.png").default + ")",
                                            width:"100%",
                                            height: "100%"
                                        }}
                                    >
                                        <Row style={{paddingTop: 60}}>
                                            <Col className="ml-auto mr-auto mb-auto text-center" md={3}>
                                                <div className="card card-profile" data-background-color="gray">
                                                    <div className="card-avatar" style={{margin: "-20px 0px"}}>
                                                        <img className="img"
                                                             style={{width: "20%"}}
                                                             src={require("assets/img/user1.png").default}/>
                                                    </div>

                                                    <div className="card-body" style={{margin: "20px 0px"}}>
                                                        <h6 className="card-category">2022-8-21</h6>

                                                        <h6  className="card-title text-muted">飞猪用户评价</h6>

                                                        <h6 className="card-description">
                                                            很好的游玩体验栈道等设施，维护的都很好，如果水量再大一些，瀑布会更壮观。燕尾瀑和天坑瀑布印象深刻，比天堂寨景色更突出。
                                                        </h6>
                                                        <br/>

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="ml-auto mr-auto mb-auto text-center" md={3}>
                                                <div className="card card-profile" data-background-color="gray">
                                                    <div className="card-avatar" style={{margin: "-20px 0px"}}>
                                                        <img className="img"
                                                             style={{width: "20%"}}
                                                             src={require("assets/img/user2.png").default}/>
                                                    </div>

                                                    <div className="card-body" style={{margin: "20px 0px"}}>
                                                        <h6 className="card-category">荷戟孤鸿</h6>

                                                        <h6  className="card-title text-muted">携程用户评价</h6>

                                                        <h6 className="card-description">
                                                            这里山高谷深，涧水奔流，茂林修竹，一派大自然的雄伟景观，人行其间，既能感受到大自然的鬼斧神工，又能充分领略大自然的馈赠！
                                                        </h6>
                                                        <br/>

                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="ml-auto mr-auto mb-auto text-center" md={3}>
                                                <div className="card card-profile" data-background-color="gray">
                                                    <div className="card-avatar" style={{margin: "-20px 0px"}}>
                                                        <img className="img"
                                                             style={{width: "20%"}}
                                                             src={require("assets/img/user3.png").default}/>
                                                    </div>

                                                    <div className="card-body" style={{margin: "20px 0px"}}>
                                                        <h6 className="card-category">M27***48</h6>

                                                        <h6 className="card-title text-muted">百度用户评价</h6>

                                                        <h6 className="card-description">
                                                            雨后，燕子河大峡谷真的很漂亮，震撼人心。它比白马大峡谷和天竺大峡谷更美丽。落基山脉非常有特色，整体氛围很好。天坑是精华必看的。这是一个不错的风景区，管理也很到位。值得一游！
                                                        </h6>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default Landing;
