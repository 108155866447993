import React from "react";
import {
    Card,
    Col,
    Container,
    Row
} from "reactstrap";
import Header from "./sections/Header";
import YZHNavbar from "../components/YZHNavbar";
import DarkFooter from "../components/DarkFooter";
import {InfoWindow, Map, Marker, NavigationControl, ZoomControl} from "react-bmapgl/dist";

function About() {
    React.useEffect(() => {
        document.body.classList.add("yzh-about-page");

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("yzh-about-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <YZHNavbar />
            <div className="wrapper">
                <div className="main">
                    <div className="section section-about-us">
                        <Container style={{marginTop: 60}} >
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="10">
                                    <h5>我们的故事</h5>
                                </Col>
                                <img
                                        alt="..."
                                        style={{marginBottom: 20}}
                                        className=" img-fluid img-raised"
                                        src={require("assets/img/about1.png").default}
                                    ></img>
                            </Row>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="10">
                                    <p>燕子河大峡谷景区（国家AAAA级旅游景区、大别山国家地质公园、国家水利风景区）由著名书画家启功先生题字，位于安徽大别山红色革命老区——全国著名将军县金寨县的燕子河镇，与华东最后一片原始森林、国家级森林公园天堂寨风景区相连，全长约5800米。峡谷最高峰海拔568米，最低处海拔约350米，峡谷最深处约218米。景区内奇石怪潭、陡崖飞瀑、云雾飘渺、风光旖旎，四季景色各异，堪称“人间仙境”。</p>
                                <p>燕子河大峡谷以险崖、奇石、幽谷、秀水而著称，以“五谷”特色向外打造。</p>
                                    <p>五谷即为：希望谷，生态谷，仙境谷，康养谷，珍馐谷。</p>
                                </Col>
                            </Row>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <img
                                        alt="..."
                                        style={{marginBottom: 20}}
                                        className=" img-fluid img-raised"
                                        src={require("assets/img/about2.png").default}
                                    ></img>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20, width: "50%"}}
                                        src={require("assets/img/about3.png").default}
                                    ></img>
                                    <p>燕子河大峡谷内春夏鸟语花香，万紫千红尽收眼底；青山绿水，五光十色 满人间。秋冬花黄树赤，层林尽染一片红；银装素裹，冰洁如镜漫山白。四季景色各异，实为罕见，堪称人间仙境，故言仙境谷。</p>

                                    <p>天然奇石千姿百态、色彩斑谰；奇峡异滩、险峰绝壁、陡崖飞瀑、云雾飘渺、风光旖旎。大大小小的潭、池、湖、岛，奇观胜景，令人惊叹不已；飞瀑流泉，古树名木，犹如置身桃花源中，且有奇珍异兽分布其间，是令人神往的绿色旅游佳境。</p>
                                </Col>
                            </Row>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <p>燕子河大峡谷内飞瀑流泉，古树名木，犹如置身桃花源中，且有奇珍异兽分布其间，是令人神往的绿色旅游佳境，故而言生态谷。</p>
                                    <p>2016年，燕子河大峡谷景区被评定为第十六批国家水利风景区，这里是一处因冰川运动遗留下来的地质景观，水量充足丰沛，从瀑布到溪流，渐行渐宽。同时景区内大大小小的潭、池、湖、岛，奇观胜景，令人惊叹不已，恍若步入人间仙境。</p>
                                    <p>景区地理条件复杂多样，是我国第三纪植物的天然避难所，为华北、华中、华东三大植物区系的交汇处。主要有国家级、省级保护植物数十种，如大别山五针松、金钱松、黄山松等。</p>
                                    <p>燕子河大峡谷一直人迹罕至，至今得以生态系统保存完整。这里山高谷深、峰险林密、人迹罕至，为野生动物繁衍生息提供了良好的栖息环境。这里野生动物丰富、鸟类众多。尤其是燕尾鸟数量多，燕子河也由此得名。燕子河大峡谷已成为动物、鸟类摄影爱好者的最爱。</p>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <img
                                        alt="..."
                                        style={{marginBottom: 20}}
                                        className=" img-fluid img-raised"
                                        src={require("assets/img/about4.png").default}
                                    ></img>
                                </Col>
                            </Row>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <img
                                        alt="..."
                                        style={{marginBottom: 20}}
                                        className=" img-fluid img-raised"
                                        src={require("assets/img/about5.png").default}
                                    ></img>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <p>燕子河大峡谷位于大别山红色革命老区--全国著名将军县金寨县的燕子河镇境内，是红色文化的发源地，也是扶贫振兴的重点地区，毗邻红岭公路，串联大别山老红色旅游体系，未来发展可期，故而称希望谷。</p>
                                    <p>燕子河大峡谷运营公司始终把扶贫，帮困，共谋，奔富，作为企业社会使命和责任，积极响应国家政策号召，并在不同时期落到实处，捐建希望小学，在旅游景点我们举行的小小讲解导游的活动，不仅可以帮助游客们更好的了解景点，同时锻炼了孩子们的能力，导游赚取的工资同样被用于学校和本地的振兴建设当中。我们始终将人民的利益放在首位，把困难和责任留给自己。我们始终是生动的，积极的，充满活力的，力求在红军文化的传承之后在打造起本地更加美好的明天。</p>
                                    <p>大家对燕子河大峡谷每一次支持我们都看在眼里，记在心里，付诸于行动之上。燕子河大峡谷运营公司将每张门票收取百分比投入到希望小学的建设之中，让本来贫困的乡镇在依靠旅游业拜托困境之后，重新更有力的向前发展。</p>
                                    <p>景区设有特色打卡点，每个打卡点都会有本地脱贫示范户的具体势力，大家可以在观看了解之后给予支持，同时每个打卡点都会有相关土特售卖：竹编，米酒，炒货，山珍，石头工艺品等等，这些产品都是由公司帮助设计，完成形象，销售流程等等加工完成，未来我们考虑增添一些线上销售渠道，尽自身最大努力给予当地农户帮助。燕子河大峡谷的美好明天，将由我们共同打造。</p>
                                </Col>
                            </Row>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <p>峡谷长度约4800米的步道，适合登山跑步各年龄段人士游玩，负氧离子含量极高，空气清新，泉水叮咚，是休闲养生的好去处，仙境度假村拥有极其健全的运动设施，泉水游泳，太极，瑜伽，禅修，体育项目（网球，羽毛球，兵乓球，沙滩排球，高尔夫），故称为康养谷。</p>
                                    <p>燕子河大峡谷拥有国内独有的平缓的木质栈道，老人们步行、婴儿推车、老人或残疾人轮椅，全程无障碍进入到河谷的核心景点，观赏或体验。景区内96%为植被覆盖，让这里的负氧离子高达20000个/立方厘米。步道登山、园区慢跑、泉水游泳、太极、瑜伽、禅修，体育项目（网球、羽毛球、乒乓球、沙滩排球、高尔夫），一切均在生态环境中。燕子河大峡谷身处大别山，负氧离子极其丰富。境内空气质量高，负氧离子平均含量远超1000个/cm3，燕子河、天堂寨等自然景区最大值超过2万个/cm3；PMl0在70微克/立方米左右。</p>
                                    <p>燕子河大峡谷山珍、水产、蔬菜、水果、家禽、家畜原生态，辅以健康的烹饪方法、膳食管理，处于生态谷之间的仙境度假村，且极尽奢华，为康养谷做了最好的注脚，也让康养谷得以打造成功成为可能。冥冥中，燕子谷的创建者早已进行了一番规划。以御园皇楼为中心，梅、兰、竹、菊四栋别墅环绕。合计33个房间，最大可居人数达60人以上，且分栋布局为各类康养群体提供高端大气、生态自然的康养度假居所。
                                        大别山物产丰饶，茶叶：六安瓜片、霍山黄芽、金寨翠眉、金寨吊锅、桐城小花。药材：茯苓、石斛、灵芝、百合、天麻、杜仲。食品：野山核桃、小吊酒、罗田板栗、罗田甜柿。丰富而高品质的滋补品，为康养谷做了最好的备注，更可以在燕子河平台开发出菜品和产品。</p>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <img
                                        alt="..."
                                        style={{marginBottom: 20}}
                                        className=" img-fluid img-raised"
                                        src={require("assets/img/about6.png").default}
                                    ></img>
                                </Col>
                            </Row>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <img
                                        alt="..."
                                        style={{marginBottom: 20}}
                                        className=" img-fluid img-raised"
                                        src={require("assets/img/about7.png").default}
                                    ></img>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="5">
                                    <p>峡谷地处南北交会地带，拥有复杂多样的生态环境和多样的生物。丰富多彩的美食也孕育其中。来到亚热带丛林茂密的燕子河大峡谷，这里不乏让人垂涎三尺的各类珍馐，是吃货的天堂，故称珍馐谷。</p>
                                    <p>大别山身跨鄂、豫、皖，是中国南北方之间重要的地质-地理-生态气候的分界线。燕子河大峡谷地处南北交会地带，拥有复杂多样的生态环境和多样的生物。丰富多彩的美食原村料也孕育其中。山珍海味是人间美食的顶端，来到亚热带丛林茂密的燕子河大峡谷，这里不乏让人垂涎三尺的各类珍馐。尤其炒货最不容错过，源于大别山，每日坚果，健康天天，燕子河的坚果凸显出一个“野”字，自然生长更美味，炒货也疯狂。</p>
                                </Col>
                            </Row>
                            <Row  id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="8">
                                    <h3 className="title">发现更多</h3>
                                </Col>
                            </Row>
                            <Row id="section-story-overview text-center">
                                <Col className="ml-auto mr-auto text-center" md="4">
                                    <div className="card">
                                        <img className="card-img-top" src={require("assets/img/about8.png").default} alt="Card image cap"></img>
                                                <div className="card-body">
                                                    <p className="card-text">歇宿</p>
                                                </div>
                                        </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="4">
                                    <div className="card">
                                        <img className="card-img-top" src={require("assets/img/about9.png").default} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <p className="card-text">游玩</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="4">
                                    <div className="card">
                                        <img className="card-img-top" src={require("assets/img/about10.png").default} alt="Card image cap"></img>
                                        <div className="card-body">
                                            <p className="card-text">体验</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default About;
