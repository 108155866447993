import React from "react";
import {
    Col,
    Container,
    Row
} from "reactstrap";
import YZHNavbar from "../components/YZHNavbar";
import DarkFooter from "../components/DarkFooter";
import {InfoWindow, Map, Marker, NavigationControl, ZoomControl} from "react-bmapgl/dist";
import SocialPlatform from "./sections/SocialPlatform";

function Stay() {
    const [tabSelected, setTabSelected] = React.useState(1);

    React.useEffect(() => {
        document.body.classList.add("yzh-stay-page");

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("yzh-stay-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <YZHNavbar />
            <div className="wrapper">
                <div className="main">
                    <div className="section section-about-us">
                        <>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview">
                            </div>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="8">
                                    <h5>居停下榻</h5>
                                </Col>
                            </Row>
                            <Container style={{marginTop: 60}}>
                                <div className="nav-tabs-navigation">
                                    <div className="nav-tabs-wrapper">
                                        <ul className="nav nav-tabs justify-content-center" data-tabs="tabs">
                                            <li className="nav-item">
                                                <a className={ tabSelected === 1 ? "nav-link active" : "nav-link "} href="#" data-toggle="tab" onClick={(e)=>{e.preventDefault(); setTabSelected(1)}}>仙境度假村</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={ tabSelected === 2 ? "nav-link active" : "nav-link "} href="#" data-toggle="tab" onClick={(e)=>{e.preventDefault(); setTabSelected(2)}}>仙境酒店</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <Row className="section-story-overview">
                                    {
                                        tabSelected === 1 ? <>
                                            <Col  className="ml-auto mr-auto text-center" md="8">
                                                <p className={"text-center"}>仙境度假村位于燕子河大峡谷景区内，为“五星级休闲度假中心”，是华东地区最高端的旅游度假村之一：设有顶级别墅数幢、四星级标准宾馆，及高尔夫练习场、网球场、沙滩排球场、羽毛球馆、乒乓球馆、健身中心等各类休闲旅游设施，是全生态的商务、会务、旅游、休闲、度假的世外桃源。燕子河大峡谷素有“大别山超级养生堂”之称，是世间难寻的“天然大氧吧”，度假村建于燕子河海拔650米峡谷中，空气清新，负氧离子含量极高，无任何污染，就其与自然的融合、优美、个性和现代化四者结合而言，堪称我国绝无仅有的顶级度假村，度假村的建筑以自然为本，并结合现代人文精神，相辅相成，建筑物和天然物融为一体。
                                                    度假村是集“自然奇观、养生居旅、生态休闲”三维一体，并不拘泥于简单观光，是个人、家庭、团队的休闲养生胜地。在这里，让你5分钟忘却自己，10分钟忘记全世界。</p>
                                            </Col>
                                            <Row>
                                                <img className="img rounded" src={require("assets/img/st1.png").default}/>
                                            </Row>
                                        </> :<>
                                            <Col  className="ml-auto mr-auto text-center" md="8">
                                                <p className={"text-center"}>仙境宾馆为四星级宾馆，这里远离城市的喧嚣和浑浊，向您伸展一幅纯朴宁静如桃园般的画卷。拥有各类客房49间，客房舒适典雅，类型齐全。
                                                    宾馆餐厅、包房可容纳数百人进餐，主理各色菜肴，尤以当地特色菜为先，令人唇齿留香，回味无穷。
                                                    宾馆内设会议室、多功能厅、KTV包厢等，具有接待团体会议、培训、食宿和个人旅游休闲度假的实力功能齐全，设备高档，服务细致入微。</p>
                                            </Col>
                                            <Row>
                                                <img className="img rounded" src={require("assets/img/st2.png").default}/>
                                            </Row>
                                        </>
                                    }
                                </Row>
                            </Container>
                        </>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default Stay;
