import React from "react";
import {
    Col,
    Container,
    Row
} from "reactstrap";
import YZHNavbar from "../components/YZHNavbar";
import DarkFooter from "../components/DarkFooter";
import {InfoWindow, Map, Marker, NavigationControl, ZoomControl} from "react-bmapgl/dist";
import SocialPlatform from "./sections/SocialPlatform";

function Activity() {

    React.useEffect(() => {
        document.body.classList.add("yzh-activity-page");

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("yzh-activity-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <YZHNavbar />
            <div className="wrapper">
                <div className="main">
                    <div className="section section-about-us">
                        <>
                            <div className="separator separator-primary"></div>
                            <div className="section-story-overview">
                            </div>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="8">
                                    <h5>游玩·体验</h5>
                                </Col>
                            </Row>
                        <Container style={{marginTop: 60}}>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <div className="card card-blog">
                                        <div className="card-image">
                                            <img className="img rounded" src={require("assets/img/ac1.png").default}/>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="category text-warning">
                                                <i className="now-ui-icons business_bulb-63"></i>
                                                卡丁车
                                            </h6>
                                            <p className="card-description">
                                                “卡丁”（karting）是一项刺激的、具有魅力的运动，最高时速大约是100公里；同时，由于车型小巧，车底板距路面仅4厘米，车手实际感觉到的速度要比车辆实际速度高2倍到3倍，使驾驶者体验到一种平时体验不到的乐趣。
                                                燕子河大峡谷卡丁车隐藏在大山深处，体验者可以编标车边欣赏峡谷的自然风光，和城市里的室内卡丁车完全不同！
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <div className="card card-blog">
                                        <div className="card-image">
                                            <img className="img rounded" src={require("assets/img/ac2.png").default}/>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="category text-warning">
                                                <i className="now-ui-icons business_bulb-63"></i>
                                                山谷滑道
                                            </h6>
                                            <p className="card-description">
                                                燕子河大峡谷山体趣味滑道沿山势而建，从停车场直达景区入口，长达1100米，在整个滑行中，跨石穿林、掠翠冲幽、蜿蜒起伏、新鲜刺激。让您在林木间畅享自由穿梭的快感，更能体验高山滑行的速度与激情。滑车有两个刹车手柄，可以自已控制速度，滑车带有安全带，游人更安全。
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <div className="card card-blog">
                                        <div className="card-image">
                                            <img className="img rounded" src={require("assets/img/ac3.png").default}/>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="category text-warning">
                                                <i className="now-ui-icons business_bulb-63"></i>
                                                玻璃吊桥
                                            </h6>
                                            <p className="card-description">
                                                安徽省境内首座玻璃吊桥，燕子河大峡谷中的一道风景线，站在桥上峡谷美景尽收眼底，而脚下又是奇石趣水，绝对让您恍惚间有一种半空漫步的感觉。
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <div className="card card-blog">
                                        <div className="card-image">
                                            <img className="img rounded" src={require("assets/img/ac4.png").default}/>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="category text-warning">
                                                <i className="now-ui-icons business_bulb-63"></i>
                                                KTV
                                            </h6>
                                            <p className="card-description">
                                                KTV娱乐、餐饮美食：
                                            </p >
                                            <p className="card-description">
                                                KTV包房的现代与奢华，顶级音响配备，专业级音质感受； 音乐曲目动态更新，引领最新视听潮流； 全新触摸屏点歌，进口全彩灯光系统，感受动感与柔美的双重体验。
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/ac8.png").default}
                                    ></img>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/ac9.png").default}
                                    ></img>
                                </Col>
                            </Row>
                            <Row>
                                <div className="card">
                                    <div className="card-body">
                                        <p>大型标准会议室、多功能厅：
                                        以仙境度假村休闲综合体为依托，以创新、多元化的理念打造集会议、商务宴请活动等功能于一体的综合服务项目。宾馆二层大型标准会议室、多功能厅均可容纳百人，为追求高品质会议、活动的企业和单位提供优质服务。完备的功能设施、高效便捷的商务中心以及高素质的服务统筹团队全面保障您活动、会议的每一个细节都达到尽善尽美。无论是数人的小型会议，还是上百人的重大商务会议等，我们都将满足您的需求。
                                        会议现场配备：
                                        高速网络互联网接入 全套的音响视听设备 专业电脑投影设备
                                            演讲专用麦克风 立式讲台、活动舞台 会议记录用品及茶水服务
                                        </p>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/ac12.png").default}
                                    ></img>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/ac13.png").default}
                                    ></img>
                                </Col>
                            </Row>
                            <Row>
                                <div className="card">
                                    <div className="card-body">
                                        <p>高尔夫练习场：
                                            仙境度假村的国际标准高尔夫练习场，是一个宁静自然的世界、一个完全挥洒自由的空间、一个流放心灵的理想地。
                                            高尔夫练习场设有标准目标果岭和沙坑，使你在练球时如身在球道、得心应手，同时还配备有夜间灯光照明设备，草坪内晚间照明充沛，雅巧精致的果岭可以拥有与正规高尔夫球比赛同样的感觉。
                                        </p>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/ac14.png").default}
                                    ></img>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/ac15.png").default}
                                    ></img>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/ac16.png").default}
                                    ></img>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid img-raised"
                                        style={{marginBottom: 20}}
                                        src={require("assets/img/ac17.png").default}
                                    ></img>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <div className="card card-blog">
                                        <div className="card-image">
                                            <img className="img rounded" src={require("assets/img/ac18.png").default}/>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="category text-warning">
                                                <i className="now-ui-icons business_bulb-63"></i>
                                                休闲运动
                                            </h6>
                                            <p className="card-description">
                                                休闲运动：各类球馆
                                                运动是舒缓身心的另一种方式，仙境度假村内建有多种运动场地，拥有完善的基础设施硬件和服务项目，
                                                可满足不同要求，让您和您的家人或朋友在这里尽情畅玩。
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="6">
                                    <div className="card card-blog">
                                        <div className="card-image">
                                            <img className="img rounded" src={require("assets/img/ac19.png").default}/>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="category text-warning">
                                                <i className="now-ui-icons business_bulb-63"></i>
                                                极限运动
                                            </h6>
                                            <p className="card-description">
                                                极限运动：攀岩、滑道、激流勇进、漂流
                                                仙境度假村内的极限运动场所都由国家专业公司设计打造。一律采用经国际质量认证的进口专业器材，安全可靠。
                                                各场地都配备专业教练及保护员，以确保每一位客人的百分百安全。这里惊险刺激，集健身、挑战与娱乐为一体。挑战自我极限，考验意志和身心的运动，您的必玩之选！
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        </>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default Activity;
