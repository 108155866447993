/*eslint-disable*/
import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import SocialPlatform from "../views/sections/SocialPlatform";
import {isMobile} from "react-device-detect";
import {Popover} from "react-tiny-popover";

function DarkFooter() {
  const [isPopoverOpenW, setIsPopoverOpenW] = React.useState(false);
  const [isPopoverOpenT, setIsPopoverOpenT] = React.useState(false);
  const [isPopoverOpenR, setIsPopoverOpenR] = React.useState(false);

  return (
    <footer className="footer" style={{backgroundColor: "white"}}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center">
            <img
                alt="..."
                className="yzh-logo"
                src={require("assets/img/logo-dark.png").default}
                style={{maxWidth: 350, marginBottom: 30, marginTop: 30}}
            ></img>
          </Col>
        </Row>
        <Row className="justify-content-center" >
          <Col className="ml-auto mr-auto text-center ">
            <div style={{width: "max-content"}}>
              <Link to={"/关于我们"}>关于我们</Link>
            </div>
          </Col>
          <Col className="ml-auto mr-auto text-center">
            <div style={{width: "max-content"}}>
              <Link to={"/游玩体验"}>游玩·体验</Link>
            </div>
          </Col>
          <Col className="ml-auto mr-auto text-center">
            <div style={{width: "max-content"}}>
              <Link to={"/居停下榻"}>居停下榻</Link>
            </div>
          </Col>
          <Col className="ml-auto mr-auto text-center">
            <div style={{width: "max-content"}}>
              <Link to={"/景区信息"}>景区信息</Link>
            </div>
          </Col>
          <Col className="ml-auto mr-auto text-center">
            <div style={{width: "max-content"}}>
              <Link to={"/风景画廊"}>风景画廊</Link>
            </div>
          </Col>
          <Col className="ml-auto mr-auto text-center">
            <div style={{width: "max-content"}}>
              <Link to={"#"}                                     onClick={(e) => {
                e.preventDefault();
                window.open("https://you.ctrip.com/sight/jinzhai2623/65752.html#ctm_ref=www_hp_bs_lst")
              }}>在线购票</Link>
            </div>
          </Col>
        </Row>
        <hr style={{margin: 60}}></hr>
        <nav>
          © {new Date().getFullYear()},{" "}
          燕子河大峡谷管理有限公司 皖ICP备2022007889号-4
        </nav>
        <div className="social-platform" >
          {navigator.userAgent.toLowerCase() === "micromessenger" ?
              <a href="#"
                 onClick={(e) => {
                   e.preventDefault();
                   window.open("https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA4NDQ2MDIwMQ==#wechat_redirect")
                 }}
              >
                <img
                    alt="..."
                    src={require("assets/img/wechat.svg").default}
                    style={{marginRight: 15}}
                ></img>
              </a> :
              <>
                <Popover
                    isOpen={isPopoverOpenW}
                    onClickOutside={() => setIsPopoverOpenW(false)}
                    positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                    content={<div>                    <img
                        alt="..."
                        src={require("assets/img/wechat.jpg").default}
                        style={{marginRight: 15, width: 100}}
                    ></img> </div>}
                >
                  <a href="#"
                     onClick={(e) => {                e.preventDefault();
                       setIsPopoverOpenW(!isPopoverOpenW)}}>
                    <img
                        alt="..."
                        src={require("assets/img/wechat.svg").default}
                        style={{marginRight: 15}}
                    ></img>
                  </a>
                </Popover>
              </>
          }


          {isMobile ?
              <a href="#"
                 onClick={(e) => {
                   e.preventDefault();
                   window.open("https://v.douyin.com/6FQtf9B/")
                 }}
              >
                <img
                    alt="..."
                    src={require("assets/img/tiktok.svg").default}
                    style={{marginRight: 15}}
                ></img>
              </a> :
              <>
                <Popover
                    isOpen={isPopoverOpenT}
                    onClickOutside={() => setIsPopoverOpenT(false)}
                    positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                    content={<div>                    <img
                        alt="..."
                        src={require("assets/img/douyin.png").default}
                        style={{marginRight: 15, width: 100}}
                    ></img> </div>}
                >
                  <a href="#"
                     onClick={(e) => {                e.preventDefault();
                       setIsPopoverOpenT(!isPopoverOpenT)}}>
                    <img
                        alt="..."
                        src={require("assets/img/tiktok.svg").default}
                        style={{marginRight: 15}}
                    ></img>
                  </a>
                </Popover>
              </>
          }
          {isMobile ?
              <a href="#"
                 onClick={(e) => {
                   e.preventDefault();
                   window.open("https://www.xiaohongshu.com/user/profile/56aef063b8c8b463bda13ac8?xhsshare=CopyLink&appuid=5c19e1d3000000000703022d&apptime=1662550883")
                 }}
              >
                <img
                    alt="..."
                    src={require("assets/img/redbook.svg").default}
                    style={{marginRight: 15}}
                ></img>
              </a> :
              <>
                <Popover
                    isOpen={isPopoverOpenR}
                    onClickOutside={() => setIsPopoverOpenR(false)}
                    positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                    content={<div>                    <img
                        alt="..."
                        src={require("assets/img/redbook.png").default}
                        style={{marginRight: 15, width: 100}}
                    ></img> </div>}
                >
                  <a href="#" onClick={(e) => {                e.preventDefault();
                    setIsPopoverOpenR(!isPopoverOpenR)}}>
                    <img
                        alt="..."
                        src={require("assets/img/redbook.svg").default}
                        style={{marginRight: 15}}
                    ></img>
                  </a>
                </Popover>
              </>
          }        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
