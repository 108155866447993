import React from "react";
import {
    Card,
    Col,
    Container,
    Row
} from "reactstrap";
import Header from "./sections/Header";
import YZHNavbar from "../components/YZHNavbar";
import DarkFooter from "../components/DarkFooter";
import {InfoWindow, Map, Marker, NavigationControl, ZoomControl} from "react-bmapgl/dist";
import SocialPlatform from "./sections/SocialPlatform";

function Shop() {
    React.useEffect(() => {
        document.body.classList.add("yzh-shop-page");

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("yzh-shop-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <YZHNavbar />
            <div className="wrapper">
                <div className="main">
                    <div className="section section-about-us">
                        <Container style={{marginTop: 60}} >
                            <Row id="section-story-overview">
                                <img
                                    alt="..."
                                    className="img-fluid img-raised"
                                    src={require("assets/img/swb1.png").default}
                                ></img>
                            </Row>
                            <Row  id="section-story-overview">
                                <Col className="ml-auto mr-auto text-center" md="8">
                                    <h3 className="title">饮用山泉水的好处</h3>
                                </Col>
                            </Row>
                            <div className="separator separator-primary"></div>
                            <Row>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <div className="card card-background" style={{backgroundImage: `url(${require("assets/img/act1.png").default}`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                        <div className="text-left" style={{marginLeft: 15, marginTop: 280}}>
                                            <h5 style={{color: '#ffffff', fontWeight: "bold"}}>矿物质丰富</h5>
                                            <h6 style={{color: '#c4dab1'}} >山泉水中重要人体必须的矿物质包括镁，钾和钙。</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <div className="card card-background" style={{backgroundImage: `url(${require("assets/img/act2.png").default}`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                        <div className="text-left" style={{marginLeft: 15, marginTop: 280}}>
                                            <h5 style={{color: '#ffffff', fontWeight: "bold"}}>露营</h5>
                                            <h6 style={{color: '#c4dab1'}} >4000平超大草坪</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <div className="card card-background" style={{backgroundImage: `url(${require("assets/img/act3.png").default}`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                        <div className="text-left" style={{marginLeft: 15, marginTop: 280}}>
                                            <h5 style={{color: '#ffffff', fontWeight: "bold"}}>度假康养</h5>
                                            <h6 style={{color: '#c4dab1'}} >高尔夫球场，网球场，室内游泳池</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="ml-auto mr-auto text-center" md="3">
                                    <div className="card card-background" style={{backgroundImage: `url(${require("assets/img/act4.png").default}`, backgroundSize: "cover", backgroundPosition: "center"}}>
                                        <div className="text-left" style={{marginLeft: 15, marginTop: 280}}>
                                            <h5 style={{color: '#ffffff', fontWeight: "bold"}}>游玩项目</h5>
                                            <h6 style={{color: '#c4dab1'}} >卡丁车，山体滑道，玻璃桥</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row id="section-story-overview">
                                <Col className="ml-auto mr-auto text-left" md="5">
                                    <h5 className="text-left">景区开放时间</h5>
                                    <h6 className="text-left">周一至周日</h6>
                                    <h6 className="text-left">07:30 - 18:30</h6>
                                    <p className="text-left">请关注我们的社交媒体获取
                                        最新景区开闭园时间、活动、及动态</p>
                                    <p className="text-left">
                                        <SocialPlatform />
                                    </p>
                                    <p className="text-left">景区服务热线：400-801-9555</p>
                                </Col>

                            </Row>
                        </Container>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default Shop;
