/*eslint-disable*/
import React from "react";

// reactstrap components
import {Button, Col, Container, Row} from "reactstrap";
import SmoothScroll from "./SmoothScroll";
import SocialPlatform from "./SocialPlatform";
// core components

function Header() {
    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    return (
        <>
            <div className="page-header clear-filter" filter-color="black" >
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/background.jpg").default + ")",
                    }}
                    ref={pageHeader}
                ></div>
                <Container>
                    <div className="content-center brand" style={{padding: 70}}>
                        <Row>
                            <Col md="12">
                                <h2>探索</h2>
                            </Col>
                        </Row>
                        <Row>
                        <img
                            alt="..."
                            className="yzh-logo"
                            src={require("assets/img/logo-yzh.png").default}
                        ></img>
                        </Row>
                    </div>
                    <div style={{float: "right", marginTop: "35vh"}}>
                        {/*<div style={{marginBottom: 30}}>*/}
                        {/*    <img*/}
                        {/*    alt="..."*/}
                        {/*    src={require("assets/img/wechat-white.svg").default}*/}
                        {/*></img>*/}
                        {/*</div>*/}
                        {/*<div style={{marginBottom: 30}}>                            <img*/}
                        {/*    alt="..."*/}
                        {/*    src={require("assets/img/tiktok-white.svg").default}*/}
                        {/*></img>*/}
                        {/*</div>*/}
                        {/*<div style={{marginBottom: 30}}>                            <img*/}
                        {/*    alt="..."*/}
                        {/*    src={require("assets/img/redbook-white.svg").default}*/}
                        {/*></img>*/}
                        {/*</div>*/}
                        {/*<SocialPlatform color={"white"} align={"vertical"} position={"left"}/>*/}
                    </div>
                    {/*<Row style={{marginTop: "70vh"}}>*/}
                    {/*    <Col md="4">*/}
                    {/*        <div className="card card-glass">*/}
                    {/*            <div className="card-body" style={{textAlign:'left'}}>*/}
                    {/*                <h6>*/}
                    {/*                    <img*/}
                    {/*                        src={require("assets/img/handicap.svg").default}*/}
                    {/*                        style={{marginRight: '30px', width: '17px'}}*/}
                    {/*                    ></img>全程无障碍栈道</h6>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col md="4">*/}
                    {/*        <div className="card card-glass">*/}
                    {/*            <div className="card-body" style={{textAlign:'left'}}>*/}
                    {/*                <h6>*/}
                    {/*                    <img*/}
                    {/*                        src={require("assets/img/mountain.svg").default}*/}
                    {/*                        style={{marginRight: '30px', width: '17px'}}*/}
                    {/*                    ></img>*/}
                    {/*                    国家 AAAA 级旅游景区</h6>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*    <Col md="4">*/}
                    {/*        /!*{SmoothScroll({to:"section-story-overview", children:                             <Button size="lg" className="btn-round" outline type="button">*!/*/}
                    {/*        /!*        了解更多*!/*/}
                    {/*        /!*    </Button>})}*!/*/}
                    {/*        <div className="card card-glass">*/}
                    {/*            <div className="card-body" style={{textAlign:'left'}}>*/}
                    {/*                <h6>*/}
                    {/*                    <img*/}
                    {/*                        src={require("assets/img/car.svg").default}*/}
                    {/*                        style={{marginRight: '30px', width: '17px'}}*/}
                    {/*                    ></img>*/}
                    {/*                    中国·安徽 红岭公路</h6>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Container>
            </div>
        </>
    );
}

export default Header;
