import React from "react";
import {
    Card,
    Col,
    Container,
    Row
} from "reactstrap";
import Header from "./sections/Header";
import YZHNavbar from "../components/YZHNavbar";
import DarkFooter from "../components/DarkFooter";
import {InfoWindow, Map, Marker, NavigationControl, ZoomControl} from "react-bmapgl/dist";
import Carousel from "./sections/Carousel";

function Gallery() {
    React.useEffect(() => {
        document.body.classList.add("yzh-gallery-page");

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("yzh-gallery-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    return (
        <>
            <YZHNavbar />
            <div className="wrapper">
                <div className="main">
                    <div className="section section-about-us">
                        <Container style={{marginTop: 60}} >
                            <Col className="ml-auto mr-auto text-center" md={8}>
                                <h3 className={"title"}>风景画廊</h3>
                            </Col>

                            <Carousel />
                        </Container>
                    </div>
                </div>
                <DarkFooter />
            </div>
        </>
    );
}

export default Gallery;
