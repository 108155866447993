import React from "react";
import {
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    UncontrolledCollapse,
} from "reactstrap";

function YZHNavbar() {
    const [navbarColor, setNavbarColor] = React.useState("");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    React.useEffect(() => {
        const updateNavbarColor = () => {
            // if (
            //     document.documentElement.scrollTop > 399 ||
            //     document.body.scrollTop > 399
            // ) {
            //     setNavbarColor("");
            // } else if (
            //     document.documentElement.scrollTop < 400 ||
            //     document.body.scrollTop < 400
            // ) {
            //     setNavbarColor("navbar-transparent");
            // }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand
                            href="https://yanzihecanyon.com"
                            target="_blank"
                            id="navbar-brand"
                        >
                            <img
                                alt="..."
                                src={require("assets/img/logo.png").default}
                                width={150}
                            ></img>            </NavbarBrand>
                        <button
                            className="navbar-toggler navbar-toggler"
                            id="navbarNavDropdown"
                            onClick={() => {
                                document.documentElement.classList.toggle("nav-open");
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span className="navbar-toggler-bar top-bar"></span>
                            <span className="navbar-toggler-bar middle-bar"></span>
                            <span className="navbar-toggler-bar bottom-bar"></span>
                        </button>
                    </div>
                    <UncontrolledCollapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                        toggler="#navbarNavDropdown"
                    >
                        <Nav navbar>
                            {/*<UncontrolledDropdown nav>*/}
                            {/*    <DropdownToggle*/}
                            {/*        aria-haspopup={true}*/}
                            {/*        caret*/}
                            {/*        color="default"*/}
                            {/*        data-toggle="dropdown"*/}
                            {/*        href="#pablo"*/}
                            {/*        id="navbarDropdownMenuLink"*/}
                            {/*        nav*/}
                            {/*        onClick={e => e.preventDefault()}*/}
                            {/*    >*/}
                            {/*        大峡谷风景区*/}
                            {/*    </DropdownToggle>*/}
                            {/*    <DropdownMenu aria-labelledby="navbarDropdownMenuLink">*/}
                            {/*        <DropdownItem*/}
                            {/*            onClick={e => e.preventDefault()}*/}
                            {/*        >*/}
                            {/*             {SmoothScroll({to:"section-story-overview",*/}
                            {/*                 children: "景区介绍", className: "dropdown-item"})}*/}
                            {/*        </DropdownItem>*/}
                            {/*        <DropdownItem*/}
                            {/*            onClick={e => e.preventDefault()}*/}
                            {/*        >*/}
                            {/*            {SmoothScroll({to:"section-imgs", children: "景区风景", className: "dropdown-item"})}*/}
                            {/*        </DropdownItem>*/}
                            {/*        <DropdownItem*/}
                            {/*            onClick={e => e.preventDefault()}*/}
                            {/*        >*/}
                            {/*            {SmoothScroll({to:"section-projects", children: "娱乐项目", className: "dropdown-item"})}*/}
                            {/*        </DropdownItem>*/}
                            {/*        <DropdownItem*/}
                            {/*            onClick={e => e.preventDefault()}*/}
                            {/*        >*/}
                            {/*            {SmoothScroll({to:"section-stay", children:"住宿", className: "dropdown-item"})}*/}
                            {/*        </DropdownItem>*/}
                            {/*        <DropdownItem*/}
                            {/*            onClick={e => e.preventDefault()}*/}
                            {/*        >*/}
                            {/*            {SmoothScroll({to:"section-marketing", children:"联系我们", className: "dropdown-item"})}*/}
                            {/*        </DropdownItem>*/}
                            {/*    </DropdownMenu>*/}
                            {/*</UncontrolledDropdown>*/}
                            <NavItem>
                                <NavLink
                                    href="/大峡谷"
                                >
                                    <p>主页</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/关于我们"
                                >
                                    <p>关于我们</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/燕谷珍馐农场"
                                >
                                    <p>燕谷农场</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/游玩体验"
                                >
                                    <p>游玩·体验</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/居停下榻"
                                >
                                    <p>居停下榻</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/景区信息"
                                >
                                    <p>景区信息</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="/风景画廊"
                                >
                                    <p>风景画廊</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open("https://map.baidu.com/poi/%E7%87%95%E5%AD%90%E6%B2%B3%E5%A4%A7%E5%B3%A1%E8%B0%B7/@12902421.252379041,3643174.223518465,16.3z?uid=1cdc4a4b62d0661726e0a00e&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl")
                                    }}
                                >
                                    <p>一键导航</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open("https://you.ctrip.com/sight/jinzhai2623/65752.html#ctm_ref=www_hp_bs_lst")
                                    }}
                                >
                                    <p>在线购票</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open("https://hotels.ctrip.com/hotels/detail/?hotelId=98957973")
                                    }}
                                >
                                    <p>度假村预订</p>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </UncontrolledCollapse>
                </Container>
            </Navbar>
        </>
    );
}

export default YZHNavbar;
