import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import Landing from "./views/Landing";
import Info from "./views/Info";
import About from "./views/About";
import Gallery from "./views/Gallery";
import Activity from "./views/Activity";
import Stay from "./views/Stay";
import Shop from "./views/Shop";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/大峡谷" render={(props) => <Landing {...props} />} />
          <Route path="/景区信息" render={(props) => <Info {...props} />} />
          <Route path="/关于我们" render={(props) => <About {...props} />} />
        <Route path="/燕谷珍馐农场" render={(props) => <Shop {...props} />} />
          <Route path="/风景画廊" render={(props) => <Gallery {...props} />} />
          <Route path="/游玩体验" render={(props) => <Activity {...props} />} />
        <Route path="/居停下榻" render={(props) => <Stay {...props} />} />
        <Redirect to="/大峡谷" />
        <Redirect from="/" to="/大峡谷" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
